import { useEffect } from "react"
import { navigate } from "gatsby"

export default () => {
	useEffect(() => {
		navigate("/")
	}, [])
	return null
}

/*
import React from "react"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout pageTitle="Not Found">
    <p>Page not found</p>
  </Layout>
)

export default NotFoundPage
*/
